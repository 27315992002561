import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const EditForm = () => {
    const navigate = useNavigate();
    const [inputVal, setInputVal] = useState('');

    const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            axios.post(
                'https://oskhubert.pl/php/edit.php',
                { pass: inputVal },
                { withCredentials: true }
            ).then((data) => {
                if(data.data === 'Zmieniono') {
                    navigate('/');
                }
            });
        } catch(error) {}
    }

    return (
        <div className="form-container mt-5">
            <form onSubmit={handleSubmit}>
                <div>
                    <h3>Edycja wiadomości na stronie</h3>
                    <div className="mt-3">
                        <ReactQuill theme="snow" value={inputVal} onChange={setInputVal} />
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <button type="submit" className="btn btn-primary">
                            Edytuj
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
