import './Break.scss';
import { Container } from 'react-bootstrap';
import { useState } from 'react';

export const Break = () => {
    const [content, setContent] = useState('');

    fetch('date.txt')
    .then((r) => r.text())
    .then(text  => {
        setContent(text);
    })

    return (
        <div className="red_bg mt-5">
            <Container className="px-5">
                <h1 className='pb-2'>
                    <span dangerouslySetInnerHTML={{__html: content}} />
                </h1>
                <p>Zadzwoń do mnie: 504-177-700</p>
            </Container>
        </div>
    );
}