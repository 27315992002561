import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './LoginForm.scss';

export const LoginForm = () => {
    const [inputVal, setInputVal] = useState('');
    const navigate = useNavigate();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputVal(e.target.value);
    };

    const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setInputVal('');
        try {
            axios.post(
                'https://oskhubert.pl/php/authenticate.php',
                { pass: inputVal }
            ).then((data) => {
                if(data.data === 'Zalogowano')
                {
                    navigate('/c32db346afb23a554a');
                }
            });
        } catch(error) {}
    }

    return (
        <div className="form-container mt-5">
            <form onSubmit={handleSubmit}>
                <div>
                    <h3>Zaloguj się</h3>
                    <div className="mt-3">
                        <input
                            id='password'
                            name='password'
                            type='password'
                            className='form-control mt-1'
                            placeholder='Podaj hasło'
                            onChange={handleChange}
                            value={inputVal}
                        />
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <button type="submit" className="btn btn-primary">
                            Zaloguj
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
