import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home, Login, Panel } from "./pages";

function App() {
    return (
        <Routes>
            <Route path="/" element={ <Home /> }/>
            <Route path="/jajecznica" element={ <Login /> }/>
            <Route path="/c32db346afb23a554a" element={ <Panel /> }/>
        </Routes>
    );
}

export default App;
