import { Banner, Blob, Break, Categories, Contact, Footer, Instructions, Navbar } from "../../components";

export const Home = () => {
    return (
        <div>
            <Navbar />
            <Banner />
            <Blob />
            <Categories />
            <Break />
            <Instructions />
            <Contact />
            <Footer />
        </div>
    );
}
