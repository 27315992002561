import { Navbar, EditForm } from "../../components";

export const Panel = () => {
    return (
        <>
            <Navbar />
            <EditForm />
        </>
    );
};
