import { LoginForm, Navbar } from "../../components";

export const Login = () => {
    return (
        <>
            <Navbar />
            <LoginForm />
        </>
    );
};
